import React from 'react';
import styles from './App.module.css';
import { version } from '../package.json';
import { CyberPnkBanner, Footer, Article } from '@cyberpnk/component-library';

//        __________________________________________________________________________________________________________
//       _____/\/\/\/\/\______________/\/\________________________________/\/\/\/\/\________________/\/\___________
//      ___/\/\__________/\/\__/\/\__/\/\__________/\/\/\____/\/\__/\/\__/\/\____/\/\__/\/\/\/\____/\/\__/\/\_____ 
//     ___/\/\__________/\/\__/\/\__/\/\/\/\____/\/\/\/\/\__/\/\/\/\____/\/\/\/\/\____/\/\__/\/\__/\/\/\/\_______  
//    ___/\/\____________/\/\/\/\__/\/\__/\/\__/\/\________/\/\________/\/\__________/\/\__/\/\__/\/\/\/\_______   
//   _____/\/\/\/\/\________/\/\__/\/\/\/\______/\/\/\/\__/\/\________/\/\__________/\/\__/\/\__/\/\__/\/\_____    
//  __________________/\/\/\/\________________________________________________________________________________     
// __________________________________________________________________________________________________________     

function App() {

  return (
    <>
      <div className={styles.App}>
        <main>
          <div className={styles.banner}>
            <CyberPnkBanner />
          </div>

          <h1 className={styles.header}>CyberPnk's Expensive Experiments</h1>

          <Article>
            <h1>Autoglyphs Renderer</h1>
            <p>Autoglyphs are already on-chain, but they don't render an image that can be used directly.  This contract generates the SVG that corresponds to the Autoglyph, based on the Autoglyphs' contact output and the rendering instructions.</p>
            <p><a target="_blank" rel="noreferrer" href="https://twitter.com/Cyberpnk9462/status/1577979425949376512">Twitter thread</a></p>            
            <p><a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0xdAD6E1fE074525cC621F171812B46983c9B70465#readContract">Autoglyphs Renderer</a></p>
          </Article>

          <Article>
            <h1>Punks Wrapper Proxy</h1>
            <p>Offer your punks for sale in the native cryptopunks market at the same time as in all the ERC721 markets.  Access all the liquidity!</p>
            <p>Based off of <a href="https://twitter.com/frankNFT_eth" target="_blank" rel="noreferrer">@FrankNFT_eth</a>'s V1 punks wrapper.</p>
            <p>It works in ETHW Mainnet too</p>
            <p><a target="_blank" rel="noreferrer" href="https://pwp.cyberpnk.win">Punks Wrapper Proxy</a></p>
          </Article>

          <Article>
            <h1>Pfp Bg</h1>
            <p>Set one of your on-chain NFTs as the background of your PFP</p>
            <p><a target="_blank" rel="noreferrer" href="https://pfpbg.cyberpnk.win">Pfp Bg</a></p>
          </Article>

          <Article>
            <h1>Token Viewer</h1>
            <p>I got bored of decoding base 64 strings, so I made a little tool to display an NFT's tokenURI.</p>
            <p><a target="_blank" rel="noreferrer" href="https://token-viewer.cyberpnk.win">Token Viewer</a></p>
          </Article>

          <Article>
            <h1>Punks Pfp</h1>
            <p>I wanted to set my punk as my avatar in my ENS domain, but I didn't want to wrap my punk. I don't like that green band. So I made a thin view contract that points to the punks one, but uses the ERC-721 standard functions.</p>
            <p>Check this <a target="_blank" rel="noreferrer" href="https://twitter.com/Cyberpnk9462/status/1519285811493101570">Twitter thread</a> for a quick how-to</p>
            <p><a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0x67f80d581281ef3a007b7fe803936258b4cab402#readContract">Punks Pfp</a></p>
          </Article>

          <Article>
            <h1>Perfect Punks</h1>
            <p>Join together your V1 and V2 punks (with the same ID) into a Perfect Punk. You can later unwrap it and get the original punks back.</p>
            <p><a target="_blank" rel="noreferrer" href="https://perfectpunks.eth.limo">Perfect Punks</a></p>
          </Article>

          <Article>
            <h1>Numbers on Tap</h1>
            <p>Do you need free NFTs to test your app?  Search no more. In Rinkeby, I can offer you the best numbers between 0 and 999999.</p>
            <p>And in Mainnet, now, for a limited time only, the number of availble numbers is reduced to 10000! (That was for emphasis, it's not the factorial of 10000.  That doesn't fit in a uint256.)</p>
            <p><a target="_blank" rel="noreferrer" href="https://numbersontap.cyberpnk.win">Numbers on Tap</a></p>
          </Article>

          <Article>
            <h1>Bitcoin is an NFT</h1>
            <p>Everybody says that, so it must be true. I mean that it has to be made into the truth.</p>
            <p>Now you can forever burn your (W)BTC and get back what BTC always wanted to be when it grew up: Art, the non-fungible store of value.</p>
            <p><a target="_blank" rel="noreferrer" href="https://btcnft.cyberpnk.win">Bitcoin is an NFT</a></p>
          </Article>

          <Article>
            <h1>Glder</h1>
            <p>There is money to be made giving money away to NFT holders. But it's tedious, and you can make an innocent mistake, like printing yourself too much money.</p>
            <p>With this turn-key standardized solution you can generate your own contract, ready in etherescan for the people to sample from your boundless generosity, with a conveniently tasteful and modest pre-mined quantity for yourself and myself.</p>
            <p>Just add an ERC721 contract address to the mix, and you're free to integrate your new token with everything you do!  Hope that feels right.</p>
            <p><a target="_blank" rel="noreferrer" href="https://glder.cyberpnk.win">Glder</a></p>
            <p><s>Ah well, turns out it's not possible to verify a contract in Etherscan created by another contract.</s> Turns out it is!  Mint away!</p>
          </Article>

        </main>
      </div>
      <div className={styles.footer}>
        <Footer version={version} />
      </div>
    </>
  );
}

export default App;
